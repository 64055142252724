import { interfaces } from "inversify";
import { ApiServiceBinder as TelemetryBinder } from "./generated/Telemetry/ApiServiceBinder";
import { ApiServiceBinder as ConfigurationServiceBinder } from "./generated/Configuration/ApiServiceBinder";
import { ApiServiceBinder as FeaturesBinder } from "./generated/Features/ApiServiceBinder";
import { ApiServiceBinder as FederatedAuthBinder } from "./generated/FederatedAuth/ApiServiceBinder";
import { ApiServiceBinder as WorkerBinder } from "./generated/Worker/ApiServiceBinder";
import { ApiServiceBinder as PluginsBinder } from "./generated/Plugins/ApiServiceBinder";
import { ApiServiceBinder as QueueStatsBinder } from "./generated/QueueStats/ApiServiceBinder";
import { ApiServiceBinder as InsightsServiceBinder } from "~/backend/generated/Insights/ApiServiceBinder";
import { ApiServiceBinder as ProfileConnectorServiceBinder } from "~/backend/generated/ProfileConnector/ApiServiceBinder";
import { ApiServiceBinder as VirtualAgentDataServiceBinder } from "~/backend/generated/VirtualAgentData/ApiServiceBinder";

import { TwilsockAdapter } from "./TwilsockAdapter/TwilsockAdapter";
import { twilsockAdapterRTTI, httpAdapterRTTI, httpAdapterTag } from "./backend.rtti";
import { TwilsockAdapterImpl } from "./TwilsockAdapter/TwilsockAdapterImpl";
import { HttpAdapter } from "./HttpAdapter/HttpAdapter";
import { HttpAdapterImpl } from "./HttpAdapter/HttpAdapterImpl";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";

export function withContainer(container: interfaces.Container): void {
    container.bind<TwilsockAdapter>(twilsockAdapterRTTI).to(TwilsockAdapterImpl).inSingletonScope();
    container.bind<HttpAdapter>(httpAdapterRTTI).to(HttpAdapterImpl).whenTargetNamed(httpAdapterTag);
    container.bind<HttpAdapter>(httpAdapterRTTI).to(SimpleHttpAdapterImpl).whenTargetIsDefault();

    TelemetryBinder.with(container);
    ConfigurationServiceBinder.with(container);
    FeaturesBinder.with(container);
    FederatedAuthBinder.with(container);
    WorkerBinder.with(container);
    PluginsBinder.with(container);
    QueueStatsBinder.with(container);
    InsightsServiceBinder.with(container);
    ProfileConnectorServiceBinder.with(container);
    VirtualAgentDataServiceBinder.with(container);
}
