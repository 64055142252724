import { interfaces } from "inversify";
import { WorkerConfigResponse } from "~/backend/generated/Worker/model/workerConfigResponse";
import { WorkerProvider, workerConfigProviderRTTI } from "~/modules/worker";

export const setWorkerConfig =
    (container: interfaces.Container) =>
    async (sessionToken: string, workerConfig: string): Promise<WorkerConfigResponse> => {
        const configProvider = container.get<WorkerProvider>(workerConfigProviderRTTI);
        const config = await configProvider(sessionToken, workerConfig);
        return config;
    };
