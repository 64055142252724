export const environmentConfigRTTI = Symbol.for("EnvironmentConfig");
export const accountConfigRTTI = Symbol.for("AccountConfig");
export const accountConfigDataRTTI = Symbol.for("AccountConfigData");
export const configDataContainerRTTI = Symbol.for("ConfigDataContainer");
export const accountConfigProviderRTTI = Symbol.for("AccountConfigProvider");
export const accountConfigUpdaterRTTI = Symbol.for("AccountConfigUpdater");
export const publicConfigProviderRTTI = Symbol.for("PublicConfigProvider");
export const publicConfigMockOptionsRTTI = Symbol.for("PublicConfigMockOptions");
export const featuresProviderRTTI = Symbol.for("FeaturesProvider");
export const getFeaturesConfigRTTI = Symbol.for("getFeaturesConfig");
export const getAccountConfigRTTI = Symbol.for("getAccountConfig");
export const accountConfigOptionsRTTI = Symbol.for("AccountConfigOptions");
export const pluginsProviderRTTI = Symbol.for("PluginsProvider");
export const getPluginsConfigRTTI = Symbol.for("getPluginsConfig");
