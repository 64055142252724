


export enum LoggerName {
    Client = "flex:Client",
    Error = "flex:Error",
    Session = "flex:Session",
    Twilsock = "flex:Twilsock",
    Auth = "flex:Auth",
    Config = "flex:Config",
    Sync = "flex:Sync",
    SyncAdapter = "flex:SyncAdapter",
    QueueStats = "flex:QueueStats",
    Storage = "flex:Storage",
    Insights = "flex:Insights",
    ProfileConnector = "flex:ProfileConnector",
    VirtualAgentData = "flex:VirtualAgentData"
    
}




export enum SdkLoggerName {
    Twilsock = "twilsock"
}




export enum TelemetryLoggerName {
    TelemetryClient = "TelemetryClient",
    TelemetryProcessor = "TelemetryProcessor",
    TelemetrySdkClient = "TelemetrySdkClient"
}




export type InternalLoggerName = LoggerName | SdkLoggerName | TelemetryLoggerName;
