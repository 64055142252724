
import { injectable, inject } from "inversify";
import { HttpAdapter } from "./HttpAdapter";
import { mapRootKeysToCamelCase, mapRootKeysToSnakeCase } from "~/utils/mapKeys";
import { AuthenticationMethod } from "../commons/authenticationMethods";
import { httpAdapterRTTI } from "~/backend/backend.rtti";

@injectable()
export class HttpAdapterImpl implements HttpAdapter {
    readonly #simpleHttpAdapter: HttpAdapter;

    constructor(@inject(httpAdapterRTTI) simpleHttpAdapter: HttpAdapter) {
        this.#simpleHttpAdapter = simpleHttpAdapter;
    }

    public async get<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        options?: { [key: string]: unknown; headers?: object }
    ): Promise<T> {
        let response: Response;
        try {
            response = await this.#simpleHttpAdapter.get(url, authMethod, options);
        } catch (error) {
            return Promise.reject(error);
        }
        return mapRootKeysToCamelCase(response) as Promise<T>;
    }

    public async post<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: {},
        options?: { [key: string]: unknown; headers?: object },
        requestContentType?: string
    ): Promise<T> {
        let response: Response;
        try {
            response = await this.#simpleHttpAdapter.post(
                url,
                authMethod,
                this.mapBody(body),
                options,
                requestContentType
            );
        } catch (error) {
            return Promise.reject(error);
        }
        return mapRootKeysToCamelCase(response) as Promise<T>;
    }

    public async put<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: {},
        requestContentType?: string
    ): Promise<T> {
        let response: Response;
        try {
            response = await this.#simpleHttpAdapter.put(url, authMethod, this.mapBody(body), requestContentType);
        } catch (error) {
            return Promise.reject(error);
        }
        return mapRootKeysToCamelCase(response) as Promise<T>;
    }

    public async delete<T>(url: string, authMethod?: AuthenticationMethod): Promise<T> {
        let response: Response;
        try {
            response = await this.#simpleHttpAdapter.delete(url, authMethod);
        } catch (error) {
            return Promise.reject(error);
        }
        return mapRootKeysToCamelCase(response) as Promise<T>;
    }

    public setIsEdgeSupported(isEdgeSupported: Boolean = false): void {
        this.#simpleHttpAdapter.setIsEdgeSupported(isEdgeSupported);
    }

    private mapBody(body: object | undefined): string | undefined {
        if (body) {
            return JSON.stringify(mapRootKeysToSnakeCase(body));
        }
        return body;
    }
}
