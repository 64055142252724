import { ContainerModule, interfaces } from "inversify";
import { ClearCache } from "~/modules/storage/ClearCache/ClearCache";
import { clearLocalStorage } from "~/modules/storage/ClearCache/clearLocalStorage";
import { LocalStorage } from "./LocalStorage/LocalStorage";
import { LocalStorageImpl } from "./LocalStorage/LocalStorageImpl";
import { clearCacheRTTI, localStorageRTTI } from "./storage.rtti";

export const storageModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<LocalStorage>(localStorageRTTI).to(LocalStorageImpl).inSingletonScope();
    bind<ClearCache>(clearCacheRTTI).toDynamicValue(clearLocalStorage);
});
