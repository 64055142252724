import { injectable, inject } from "inversify";
import { ClientOptions } from "~/modules/client";
import { SessionOptions, sessionOptionsRTTI } from "~/modules/session";
import { LoggerOptions } from "~/modules/logger";
import { TelemetryOptions, telemetryOptionsRTTI } from "~/modules/telemetry";
import { AccountConfigOptions, accountConfigOptionsRTTI } from "~/modules/config";
import { getDefaultLoggerOptions } from "~/modules/logger/LoggerFactory/getLogger";

@injectable()
export class ClientOptionsStore implements ClientOptions {
    readonly #session: SessionOptions;

    readonly #logger: LoggerOptions;

    readonly #telemetry: TelemetryOptions;

    readonly #accountConfig: AccountConfigOptions;

    
    
    #region: string;

    #appName: string;

    #appVersion: string;

    constructor(
        @inject(sessionOptionsRTTI) session: SessionOptions,
        @inject(telemetryOptionsRTTI) telemetry: TelemetryOptions,
        @inject(accountConfigOptionsRTTI) accountConfig: AccountConfigOptions
    ) {
        this.#session = session;
        this.#logger = getDefaultLoggerOptions();
        this.#telemetry = telemetry;
        this.#accountConfig = accountConfig;
    }

    get session(): SessionOptions {
        return this.#session;
    }

    get logger(): LoggerOptions {
        return this.#logger;
    }

    get telemetry(): TelemetryOptions {
        return this.#telemetry;
    }

    get accountConfig(): AccountConfigOptions {
        return this.#accountConfig;
    }

    get region(): string {
        return this.#region;
    }

    set region(value: string) {
        this.#region = value;
    }

    get appName(): string {
        return this.#appName;
    }

    set appName(value: string) {
        this.#appName = value;
    }

    get appVersion(): string {
        return this.#appVersion;
    }

    set appVersion(value: string) {
        this.#appVersion = value;
    }
}
