












import { inject, injectable } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { WorkerConfigResponse } from "../model/workerConfigResponse";

import { WorkerConfigService } from "./workerConfig.serviceInterface";

@injectable()
export class WorkerConfigServiceImpl implements WorkerConfigService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) simpleHttpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.simpleHttpAdapter = simpleHttpAdapter;
        this.#throwError = throwError;
        this.simpleHttpAdapter.setIsEdgeSupported(false);
    }

    





    public async updateWorkerConfig(body: string, options?: object): Promise<WorkerConfigResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (body === null || body === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter body was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.post<WorkerConfigResponse>(
                `https://preview[region].twilio.com/Flex/Worker/Instance`,
                AuthenticationMethod.Basic,
                body,
                options,
                "application/x-www-form-urlencoded"
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `WorkerConfigService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        return response;
    }
}
