import { inject, injectable } from "inversify";
import { federatedAuthServiceRTTI } from "~/backend/backend.rtti";
import { FederatedAuthService } from "~/backend/generated/FederatedAuth/api/federatedAuth.serviceInterface";
import { Authenticator, IdpConfig } from "~/modules/auth";
import { authenticatorDataContainerRTTI } from "~/modules/auth/auth.rtti";
import { TokenData, TokenRefreshResult } from "~/modules/auth/Authenticator/Authenticator";
import { AuthenticatorDataContainer } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainer";
import { InternalError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { assertNotEmptyString } from "~/utils/assert";
import * as FederatedAuthHelper from "./FederatedAuthHelper";

@injectable()
export class FederatedAuth implements Authenticator {
    readonly #authenticatorDataContainer: AuthenticatorDataContainer;

    readonly #logger: Logger;

    readonly #federatedAuthService: FederatedAuthService;

    constructor(
        @inject(authenticatorDataContainerRTTI) authenticatorDataContainer: AuthenticatorDataContainer,
        @inject(federatedAuthServiceRTTI) federatedAuthService: FederatedAuthService
    ) {
        this.#authenticatorDataContainer = authenticatorDataContainer;
        this.#logger = getLogger(LoggerName.Auth);
        this.#federatedAuthService = federatedAuthService;
    }

    public async getIdpUrl(config: IdpConfig): Promise<string> {
        assertNotEmptyString(config.redirectUrl, "redirect url");

        const payload = FederatedAuthHelper.getSSOLoginRequestBody(config);
        const accountSid = this.#authenticatorDataContainer.accountSid;

        const data = await this.#federatedAuthService.getIdpUrl(accountSid, payload);
        if (!data.location) {
            this.#logger.error("No redirect location from /authenticate request, data: ", data);
            throw new InternalError("Invalid response from /authenticate endpoint");
        }
        return data.location;
    }

    async validateToken(token: string): Promise<TokenData> {
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const tokenData = await this.#federatedAuthService.validateToken(accountSid, { token }, { token });

        return {
            roles: tokenData.roles,
            valid: tokenData.valid,
            dateExpired: tokenData.expiration,
            identity: tokenData.identity
        };
    }

    async refreshToken(token: string): Promise<TokenRefreshResult> {
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const tokenRefreshResult = await this.#federatedAuthService.refreshToken(accountSid, {
            token
        });
        return { token: tokenRefreshResult.token, dateExpired: tokenRefreshResult.expiration };
    }
}
