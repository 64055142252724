import { interfaces } from "inversify";
import { Plugin } from "~/backend/generated/Plugins/model/plugin";
import { PluginsProvider, pluginsProviderRTTI } from "~/modules/config";

export const getPluginsConfig =
    (container: interfaces.Container) =>
    async (token: string, queryParams: string): Promise<Array<Plugin>> => {
        const pluginsConfigProvider = container.get<PluginsProvider>(pluginsProviderRTTI);
        const config = await pluginsConfigProvider(token, queryParams);
        return config;
    };
