import { injectable, inject, postConstruct } from "inversify";
import EventEmitter from "events";
import { Client, ClientConfigType, ClientEvent } from "~/modules/client";
import { Role } from "~/modules/auth";
import { accountConfigRTTI, AccountConfig } from "~/modules/config";
import { Twilsock, twilsockRTTI, TwilsockEvent } from "~/modules/websocket";
import { Session, SessionEvent, sessionRTTI } from "~/modules/session";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { TelemetryClient, TelemetryClientFactory, telemetryClientFactoryRTTI } from "~/modules/telemetry";
import {
    telemetrySdkClientRTTI,
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkClient,
    TelemetrySdkEventName,
    TelemetrySdkEventSource
} from "~/modules/telemetrySdkClient";
import { Emitter, proxyEvent } from "~/modules/events";
import { InsightsApis, insightsRTTI } from "~/modules/insights/historicalReporting/Definitions";
import { ProfileConnectorApis, profileConnectorRTTI } from "~/modules/profileConnector/Definitions";
import { VirtualAgentDataApi, virtualAgentDataRTTI } from "~/modules/virtualAgentData/Definitions";

@injectable()
export class ClientImpl implements Client {
    readonly #session: Session;

    readonly #connection: Twilsock;

    readonly #logger: Logger;

    readonly #telemetryClientFactory: TelemetryClientFactory<any>; 

    public readonly config: ClientConfigType;

    readonly #telemetrySdkClient: TelemetrySdkClient;

    readonly #emitter: Emitter;

    readonly #insightsApis: InsightsApis;

    readonly #profileConnector: ProfileConnectorApis;

    readonly #virtualAgentDataApi: VirtualAgentDataApi;

    constructor(
        @inject(sessionRTTI) session: Session,
        @inject(twilsockRTTI) connection: Twilsock,
        @inject(accountConfigRTTI) account: AccountConfig,
        @inject(telemetryClientFactoryRTTI) telemetryClientFactory: TelemetryClientFactory<any>, 
        @inject(telemetrySdkClientRTTI) telemetrySdkClient: TelemetrySdkClient,
        @inject(insightsRTTI) insightsApi: InsightsApis,
        @inject(profileConnectorRTTI) profileConnectorApi: ProfileConnectorApis,
        @inject(virtualAgentDataRTTI) virtualAgentDataApi: VirtualAgentDataApi
    ) {
        this.#session = session;
        this.#connection = connection;
        this.config = {
            account
        };
        this.#telemetryClientFactory = telemetryClientFactory;
        this.#telemetrySdkClient = telemetrySdkClient;
        this.#logger = getLogger(LoggerName.Client);
        this.#emitter = new EventEmitter();
        this.#insightsApis = insightsApi;
        this.#profileConnector = profileConnectorApi;
        this.#virtualAgentDataApi = virtualAgentDataApi;
    }

    @postConstruct()
    setupProxies(): void {
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenAboutToExpire, ClientEvent.TokenAboutToExpire);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenExpired, ClientEvent.TokenExpired);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenUpdated, ClientEvent.TokenUpdated);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.ConnectionError, ClientEvent.ConnectionLost);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Connected, ClientEvent.ConnectionRestored);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Disconnected, ClientEvent.Disconnected);
        proxyEvent(this.#session, this.#emitter, SessionEvent.TokenAutoUpdateFailed, ClientEvent.TokenAutoUpdateFailed);
        proxyEvent(
            this.#session,
            this.#emitter,
            SessionEvent.TokenMaxLifetimeReached,
            ClientEvent.TokenMaxLifetimeReached
        );
    }

    get insights(): InsightsApis {
        return this.#insightsApis;
    }

    async updateToken(token: string): Promise<void> {
        await this.#session.updateToken(token);
    }

    #sendDestroyEvent = async (): Promise<void> => {
        try {
            const telemetrySdkClient = this.#telemetrySdkClient;
            const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
            await group.addEvents({
                eventName: TelemetrySdkEventName.ClientDestroyed,
                eventSource: TelemetrySdkEventSource.Client
            });
        } catch (e) {
            this.#logger.error("Failed to send telemetry destroy event", e);
        }
    };

    async destroy(): Promise<void> {
        await this.#sendDestroyEvent();
        this.#logger.debug("client log out");
        await this.#session.destroy();
        await this.#insightsApis.destroy();
        this.#emitter.removeAllListeners();
    }

    get roles(): Array<Role> {
        return [...this.#session.roles];
    }

    get token(): string {
        return this.#session.token;
    }

    get profileConnector(): ProfileConnectorApis {
        return this.#profileConnector;
    }

    get virtualAgentData(): VirtualAgentDataApi {
        return this.#virtualAgentDataApi;
    }

    createTelemetryClient<U extends object>(name: string): TelemetryClient<U> {
        return this.#telemetryClientFactory(name);
    }

    addListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }
}
