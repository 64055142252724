import { ContainerModule, interfaces } from "inversify";
import {
    AccountConfig,
    AccountConfigProvider,
    AccountConfigData,
    EnvironmentConfig,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    PublicConfig,
    AccountConfigDataGetter,
    AccountConfigOptions,
    accountConfigRTTI,
    accountConfigProviderRTTI,
    environmentConfigRTTI,
    publicConfigProviderRTTI,
    configDataContainerRTTI,
    featuresProviderRTTI,
    getAccountConfigRTTI,
    accountConfigOptionsRTTI,
    FeaturesProvider,
    FeaturesConfigDataGetter,
    FeaturesConfig,
    PluginsProvider,
    PluginsConfigDataGetter,
    Plugin
} from "~/modules/config";
import { accountConfigProvider } from "./AccountConfigProvider/AccountConfigProvider";
import {
    accountConfigUpdaterRTTI,
    getFeaturesConfigRTTI,
    getPluginsConfigRTTI,
    pluginsProviderRTTI
} from "./config.rtti";
import { getEnvironmentConfig } from "./EnvironmentConfig/EnvironmentConfigImpl";
import { AccountConfigStore } from "./AccountConfig/AccountConfigImpl/AccountConfigStore/AccountConfigStore";
import { getPublicConfig } from "./PublicConfigProvider/getPublicConfig";
import { XOR } from "~/utils/utilityTypes";
import { updateAccountConfig } from "./AccountConfig/AccountConfigImpl/AccountConfigUpdate/updateAccountConfig";
import { ConfigDataContainer } from "./AccountConfig/ConfigDataContainer";
import { AccountConfigDataContainer } from "./AccountConfig/AccountConfigImpl/AccountConfigDataContainer/AccountConfigDataContainer";
import { AccountConfigUpdater } from "./AccountConfig/AccountConfigUpdater";
import { getAccountConfigData } from "./AccountConfigDataGetter/getAccountConfigData";
import { getDefaultAccountConfigOptions } from "./AccountConfigOptions/getDefaultAccountConfigOptions";
import { getFeaturesConfig } from "~/modules/config/FeaturesConfigDataGetter/getFeaturesConfig";
import { featuresProviderImpl } from "~/modules/config/FeaturesProvider/featuresProviderImpl/featuresProviderImpl";
import { pluginsProviderImpl } from "~/modules/config/PluginsProvider/PluginsProviderImpl/PluginsProviderImpl";
import { getPluginsConfig } from "~/modules/config/PluginsConfigDataGetter/getPluginsConfig";

export const configModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<AccountConfig>(accountConfigRTTI).to(AccountConfigStore).inSingletonScope();
    bind<ConfigDataContainer<AccountConfigData>>(configDataContainerRTTI)
        .to(AccountConfigDataContainer)
        .inSingletonScope();
    bind<AccountConfigProvider>(accountConfigProviderRTTI).toProvider<AccountConfigData>(
        (context: interfaces.Context) => accountConfigProvider(context.container)
    );
    bind<AccountConfigUpdater>(accountConfigUpdaterRTTI).toDynamicValue((context: interfaces.Context) => {
        return (accountSid: string, config: Partial<AccountConfigData>) =>
            updateAccountConfig(context.container, accountSid, config);
    });
    bind<EnvironmentConfig>(environmentConfigRTTI).toDynamicValue(getEnvironmentConfig);
    bind<PublicConfigProvider>(publicConfigProviderRTTI).toProvider<PublicConfig>((context: interfaces.Context) => {
        return (option: XOR<AccountSidOption, RuntimeDomainOption>) => getPublicConfig(context.container, option);
    });
    bind<FeaturesProvider>(featuresProviderRTTI).toProvider<FeaturesConfig>(featuresProviderImpl);
    bind<FeaturesConfigDataGetter>(getFeaturesConfigRTTI).toDynamicValue((context: interfaces.Context) =>
        getFeaturesConfig(context.container)
    );

    bind<PluginsProvider>(pluginsProviderRTTI).toProvider<Array<Plugin>>(pluginsProviderImpl);
    bind<PluginsConfigDataGetter>(getPluginsConfigRTTI).toDynamicValue((context: interfaces.Context) =>
        getPluginsConfig(context.container)
    );

    bind<AccountConfigDataGetter>(getAccountConfigRTTI).toDynamicValue((context: interfaces.Context) =>
        getAccountConfigData(context.container)
    );
    bind<AccountConfigOptions>(accountConfigOptionsRTTI).toConstantValue(getDefaultAccountConfigOptions());
});
