
import { interfaces } from "inversify";
import { ChannelData } from "../QueueStatsChannelsProvider";
import { queueStatsServiceRTTI } from "~/backend/backend.rtti";
import { QuequeStatsService } from "~/backend/generated/QueueStats/api/quequeStats.serviceInterface";
import { ErrorSeverity, ErrorCode, ThrowAndReportErrorFunction, throwAndReportErrorRTTI } from "~/modules/error";
import { getLogger, LoggerName } from "~/modules/logger";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const QueueStatsChannelsProviderImpl =
    ({ container }: interfaces.Context) =>
    async (sessionToken: string, workspaceSid: string, queryParams?: string): Promise<ChannelData> => {
        const queueStatsService = container.get<QuequeStatsService>(queueStatsServiceRTTI);
        const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
        const logger = getLogger(LoggerName.Config);

        let channelsData: ChannelData;

        try {
            if (queryParams) {
                const queryParamData = new URLSearchParams(queryParams);
                if (queryParamData.get("PageSize") && queryParamData.get("Page")) {
                    channelsData = await queueStatsService.fetchChannelsByPage(
                        workspaceSid,
                        "PageSize",
                        queryParamData.get("PageSize") || "",
                        "Page",
                        queryParamData.get("Page") || "",
                        { token: sessionToken }
                    );
                } else {
                    throw new Error("Invalid query Parameters");
                }
            } else {
                channelsData = await queueStatsService.fetchChannels(workspaceSid, { token: sessionToken });
            }
        } catch (err) {
            logger.warn(`Failed to fetch task channels: ${err}`);

            if (err.message === "Invalid query Parameters") {
                return throwAndReportError(
                    ErrorCode.InvalidParams,
                    { ...metadata, severity: ErrorSeverity.Error },
                    err.message
                );
            }
            const message = `Failed to fetch task channels: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
        }
        return channelsData;
    };
