import { interfaces } from "inversify";

import { WorkerConfigServiceImpl } from "./api/workerConfig.service";
import { WorkerConfigService } from "./api/workerConfig.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<WorkerConfigService>("WorkerConfigService").to(WorkerConfigServiceImpl).inSingletonScope();
    }
}
