import { interfaces } from "inversify";
import { FeaturesConfig } from "../FeaturesProvider";
import { getLogger, LoggerName } from "~/modules/logger";
import { ThrowAndReportErrorFunction, throwAndReportErrorRTTI, ErrorSeverity, ErrorCode } from "~/modules/error";
import { LocalStorage, localStorageRTTI, LocalStorageKeys } from "~/modules/storage";
import { featuresServiceRTTI } from "~/backend/backend.rtti";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { FeaturesService } from "~/backend/generated/Features/api/features.serviceInterface";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const featuresProviderImpl =
    ({ container }: interfaces.Context) =>
    async (sessionToken: string): Promise<FeaturesConfig> => {
        const featuresService = container.get<FeaturesService>(featuresServiceRTTI);
        const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
        const storage = container.get<LocalStorage>(localStorageRTTI);
        const logger = getLogger(LoggerName.Config);

        let featuresData: FeaturesConfig;

        try {
            featuresData = await featuresService.fetchFeatures({ token: sessionToken });
            storage.setCachedItem(LocalStorageKeys.FeaturesConfig, featuresData);
        } catch (err) {
            const cachedFeatures = storage.getCachedItem<FeaturesConfig>(LocalStorageKeys.FeaturesConfig);

            if (cachedFeatures === undefined) {
                const message = `Failed to fetch features: ${err}. No cache found.`;
                const errorCode = err.code || ErrorCode.Unknown;
                return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
            }

            logger.warn(`Failed to fetch features: ${err}. Using cache instead`);
            featuresData = cachedFeatures;
        }

        return featuresData;
    };
