import { inject, injectable } from "inversify";
import { EventEmitter } from "events";
import { InsightsService } from "~/backend/generated/Insights/api/insights.serviceInterface";
import { InsightsUserRoles } from "~/backend/generated/Insights/model/insightsUserRoles";
import { SessionSuccess } from "~/backend/generated/Insights/model/sessionSuccess";
import { getLogger, LoggerName } from "~/modules/logger";
import { Session, sessionRTTI } from "~/modules/session";
import { GDTokenEvents, InsightsApis } from "./Definitions";





@injectable()
export class InsightsImpl implements InsightsApis {
    readonly #insightsService;

    readonly #logger;

    readonly #session;

    #interval: NodeJS.Timeout | null;

    #tokenExpiry: string;

    public eventEmitter: EventEmitter = new EventEmitter();

    
    #TOKEN_ABOUT_TO_EXPIRE_IN_MINUTES = 1;

    
    #GD_SESSION_VALIDATION_INTERVAL = 30000;

    readonly events: GDTokenEvents = {
        TokenUpdated: "TokenUpdated",
        TokenUpdateFailed: "TokenUpdateFailed"
    };

    constructor(@inject("InsightsService") insightsService: InsightsService, @inject(sessionRTTI) session: Session) {
        this.#insightsService = insightsService;
        this.#session = session;
        this.#logger = getLogger(LoggerName.Insights);
        this.autoGenerateGoodDataSession = this.autoGenerateGoodDataSession.bind(this);

        this.#interval = setInterval(this.autoGenerateGoodDataSession, this.#GD_SESSION_VALIDATION_INTERVAL);
        this.#logger.info("InsightsImpl created!");
    }

    getInsightsRolesForUser(): Promise<InsightsUserRoles> {
        const token = this.#session.token;

        if (!token) {
            this.#logger.error("getInsightsRolesForUser called without token");
            throw new Error("getInsightsRolesForUser called without token");
        }

        this.#logger.info("getInsightsRolesForUser was called");

        return this.#insightsService.getInsightsRolesForUser({
            token
        });
    }

    isCloseToExpiry(): boolean {
        const sessionExpiryDate = this.#tokenExpiry;
        const currentTimeStamp = Date.now();
        const timeDifferenceInMinutes = (Date.parse(sessionExpiryDate) - currentTimeStamp) / 1000 / 60;
        return Math.floor(timeDifferenceInMinutes) <= this.#TOKEN_ABOUT_TO_EXPIRE_IN_MINUTES;
    }

    async autoGenerateGoodDataSession() {
        if (!this.isCloseToExpiry()) {
            return;
        }

        const token = this.#session.token;

        if (!token) {
            return;
        }

        try {
            const gdSession = await this.generateGoodDataSession();
            this.eventEmitter.emit(this.events.TokenUpdated, gdSession);
        } catch (error) {
            this.eventEmitter.emit(this.events.TokenUpdateFailed);
        }
    }

    async generateGoodDataSession(): Promise<SessionSuccess> {
        const token = this.#session.token;

        if (!token) {
            this.#logger.error("generateGoodDataSession called without token");
            throw new Error("generateGoodDataSession called without token");
        }

        this.#logger.info("generateGoodDataSession was called");

        try {
            
            const emptyObject: object = {};
            const response = await this.#insightsService.getGoodDataSessionForUser(emptyObject, {
                token
            });
            this.#tokenExpiry = response.sessionExpiry;
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    get interval(): NodeJS.Timeout | null {
        return this.#interval;
    }

    destroy(): void {
        if (this.#interval) {
            clearInterval(this.#interval);
            this.#interval = null;
        }
    }
}
