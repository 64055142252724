import type { VirtualAgentData } from "~/backend/generated/VirtualAgentData/model/virtualAgentData";

export type { VirtualAgentDataSentences } from "~/backend/generated/VirtualAgentData/model/virtualAgentDataSentences";
export type { VirtualAgentData } from "~/backend/generated/VirtualAgentData/model/virtualAgentData";
export const virtualAgentDataRTTI = Symbol.for("virtualAgentImpl");




export interface VirtualAgentDataApi {
    getV1VirtualAgentData: (contextLookupSid: string) => Promise<VirtualAgentData>;
}
