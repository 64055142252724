import { interfaces } from "inversify";
import { AccountConfigData, configDataContainerRTTI, AccountConfigOptions } from "~/modules/config";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";
import { getLogger, LoggerName } from "~/modules/logger";
import { ThrowAndReportErrorFunction, throwAndReportErrorRTTI, ErrorSeverity, ErrorCode } from "~/modules/error";
import { LocalStorage, localStorageRTTI, LocalStorageKeys } from "~/modules/storage";
import { ClientOptions, clientOptionsRTTI } from "~/modules/client";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { configurationServiceRTTI } from "~/backend/backend.rtti";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};




export type AccountConfigProvider = (
    sessionToken: string,
    options?: AccountConfigOptions
) => Promise<AccountConfigData>;




export const accountConfigProvider =
    (container: interfaces.Container) =>
    async (sessionToken: string, options: AccountConfigOptions = {}): Promise<AccountConfigData> => {
        const dataContainer = container.get<ConfigDataContainer<AccountConfigData>>(configDataContainerRTTI);
        const configurationService: ConfigurationService =
            container.get<ConfigurationService>(configurationServiceRTTI);
        const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
        const storage = container.get<LocalStorage>(localStorageRTTI);
        const logger = getLogger(LoggerName.Config);
        const clientOptions: ClientOptions = container.get<ClientOptions>(clientOptionsRTTI);

        let configData: AccountConfigData;

        const uiVersion = clientOptions?.accountConfig.query?.UiVersion || options?.query?.UiVersion;

        try {
            configData = await configurationService.fetchConfiguration("UiVersion", uiVersion, {
                token: sessionToken
            });
            storage.setCachedItem(LocalStorageKeys.AccountConfig, configData);
        } catch (err) {
            const cachedConfig = storage.getCachedItem<AccountConfigData>(LocalStorageKeys.AccountConfig);

            if (cachedConfig === undefined) {
                const message = `Failed to fetch account configuration: ${err}. No cache found.`;
                const errorCode = err.code || ErrorCode.Unknown;
                return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
            }

            cachedConfig.dateCreated = new Date(cachedConfig.dateCreated);
            cachedConfig.dateUpdated = new Date(cachedConfig.dateUpdated);

            logger.warn(`Failed to fetch account configuration: ${err}. Using cache instead`);
            configData = cachedConfig;
        }
        dataContainer.set(configData);
        return configData;
    };
