import { interfaces } from "inversify";

import { FeaturesServiceImpl } from "./api/features.service";
import { FeaturesService } from "./api/features.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<FeaturesService>("FeaturesService").to(FeaturesServiceImpl).inSingletonScope();
    }
}
