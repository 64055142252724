
import { version as packageVersion } from "package.json";
import { createContainer } from "~/ioc.config";
import {
    publicConfigProviderRTTI,
    PublicConfigProvider,
    AccountConfigDataGetter,
    FeaturesConfigDataGetter,
    getAccountConfigRTTI,
    getFeaturesConfigRTTI,
    PluginsConfigDataGetter,
    getPluginsConfigRTTI
} from "~/modules/config";
import { clientProviderRTTI, ClientProvider } from "~/modules/client";
import { authenticatorFactoryRTTI, twilioIdpUrlRTTI, AuthenticatorFactory, TwilioIdpUrlFunction } from "~/modules/auth";
import { getLogger as getLoggerFromModule } from "~/modules/logger";
import { createPureFactory } from "~/ioc.helper";
import { ClearCache } from "~/modules/storage";
import { clearCacheRTTI } from "~/modules/storage/storage.rtti";
import {
    QueueStatsChannelsDataGetter,
    QueueStatsQueuesDataGetter,
    getQueueStatsChannelsRTTI,
    getQueueStatsQueuesRTTI
} from "~/modules/queueStats";
import { setWorkerConfigRTTI, WorkerConfigDataSetter } from "~/modules/worker";

export type { SessionOptions } from "./modules/session";
export type { DeepPartial } from "./utils/DeepPartial";
export type { Without, XOR } from "./utils/utilityTypes";
export type {
    AccountConfig,
    AccountConfigProvider,
    AccountConfigKeys,
    AccountConfigData,
    IntegrationsConfig,
    OutboundFlows,
    SkillDefinition,
    QueueStatsConfiguration,
    PublicConfig,
    PublicConfigAttributes,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    TaskQueue,
    WorkerChannel,
    DefaultQueueStatsConfiguration,
    QueueConfiguration,
    QueueChannelConfiguration,
    FeaturesConfigDataGetter,
    PluginsConfigDataGetter,
    Plugin,
    FeaturesConfig,
    FeaturesList,
    AccountConfigDataGetter,
    AccountConfigOptions,
    ChannelConfig,
    CbmAttachments,
    DebuggerIntegration,
    FlexUiStatusReport
} from "./modules/config";
export type { Logger, LoggerFactory, LoggerOptions, LoggerLevel, LoggerLevelNumbers } from "./modules/logger";
export { LoggerName } from "./modules/logger";
export type { Client, ClientProvider, ClientOptions, ClientConfigType } from "./modules/client";
export { ClientEvent } from "./modules/client";
export type {
    Authenticator,
    AuthenticatorFactory,
    TwilioIdpUrlFunction,
    IdpConfig,
    Role,
    TokenData,
    TokenRefreshResult
} from "./modules/auth";
export type { EventPublisher } from "./modules/events";
export type {
    TelemetryEvent,
    TelemetryErrorEvent,
    TelemetryEventGroup,
    TelemetryClient,
    TelemetryProcessingResult,
    TelemetryOptions
} from "./modules/telemetry";
export type { ClearCache } from "~/modules/storage";
export type {
    QueueStatsChannelsDataGetter,
    QueueStatsQueuesDataGetter,
    ChannelData,
    ChannelDataChannels,
    ChannelDataMeta,
    ChannelDataLinks,
    QueuesData,
    QueuesDataLinks,
    QueuesDataMeta,
    QueuesDataTaskQueues
} from "~/modules/queueStats";
export type {
    WorkerConfigDataSetter,
    WorkerConfigResponse,
    WorkerConfigResponseAttributes,
    WorkerConfigResponseAttributesDisabledSkills,
    WorkerConfigResponseAttributesRouting
} from "~/modules/worker";
export type {
    InsightsApis as Insights,
    InsightsUserRoles,
    SessionSuccess,
    GDTokenEvents
} from "~/modules/insights/historicalReporting/Definitions";
export { hasPermission } from "~/modules/auth/Permission";
export type {
    VirtualAgentDataApi,
    VirtualAgentData,
    VirtualAgentDataSentences
} from "~/modules/virtualAgentData/Definitions";

export type {
    ProfileConnectorApis as ProfileConnector,
    ProfilesResponse,
    Profile,
    ProfileAddress,
    ProfileContact,
    ProfileAttribute,
    RankedProfile,
    FindProfilesRequest,
    IdentifiersResponse,
    TraitsResponse,
    Identifier,
    Trait
} from "~/modules/profileConnector/Definitions";






export const auth = createPureFactory<AuthenticatorFactory>(createContainer)(authenticatorFactoryRTTI);




export const getLogger = getLoggerFromModule;



export const getTwilioIdpUrl = createPureFactory<TwilioIdpUrlFunction>(createContainer)(twilioIdpUrlRTTI);




export const getPublicConfig = createPureFactory<PublicConfigProvider>(createContainer)(publicConfigProviderRTTI);




export const getAccountConfig = createPureFactory<AccountConfigDataGetter>(createContainer)(getAccountConfigRTTI);




export const getFeaturesConfig = createPureFactory<FeaturesConfigDataGetter>(createContainer)(getFeaturesConfigRTTI);




export const setWorkerConfig = createPureFactory<WorkerConfigDataSetter>(createContainer)(setWorkerConfigRTTI);




export const getPluginsConfig = createPureFactory<PluginsConfigDataGetter>(createContainer)(getPluginsConfigRTTI);




export const getTaskChannels =
    createPureFactory<QueueStatsChannelsDataGetter>(createContainer)(getQueueStatsChannelsRTTI);




export const getTaskQueues = createPureFactory<QueueStatsQueuesDataGetter>(createContainer)(getQueueStatsQueuesRTTI);




export const createClient = createPureFactory<ClientProvider>(createContainer)(clientProviderRTTI);




export const clearCache = createPureFactory<ClearCache>(createContainer)(clearCacheRTTI);




export const version: string = packageVersion;

export type { FlexSdkErrorMetadata } from "./modules/error";
export { FlexSdkError, ErrorCode, ErrorSeverity } from "./modules/error";
