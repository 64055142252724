import { interfaces } from "inversify";

import { InsightsServiceImpl } from "./api/insights.service";
import { InsightsService } from "./api/insights.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<InsightsService>("InsightsService").to(InsightsServiceImpl).inSingletonScope();
    }
}
