












import { inject, injectable, named } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI, httpAdapterTag } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { FeaturesConfig } from "../model/featuresConfig";

import { FeaturesService } from "./features.serviceInterface";

@injectable()
export class FeaturesServiceImpl implements FeaturesService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) @named(httpAdapterTag) httpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.httpAdapter = httpAdapter;
        this.#throwError = throwError;
        this.httpAdapter.setIsEdgeSupported(true);
    }

    




    public async fetchFeatures(options?: object): Promise<FeaturesConfig> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.get<FeaturesConfig>(
                `https://flex[region].twilio.com/api/v1/Session`,
                AuthenticationMethod.HostedFlex,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FeaturesService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<FeaturesConfig>(response);
        return processedResponse;
    }
}
