import { interfaces } from "inversify";
import { ChannelData } from "~/backend/generated/QueueStats/model/channelData";
import { queueStatsChannelsProviderRTTI, QueueStatsChannelsProvider } from "~/modules/queueStats";

export const getQueueStatsChannels =
    (container: interfaces.Container) =>
    async (sessionToken: string, workspaceSid: string, queryParams?: string): Promise<ChannelData> => {
        const configProvider = container.get<QueueStatsChannelsProvider>(queueStatsChannelsProviderRTTI);
        const config = await configProvider(sessionToken, workspaceSid, queryParams);
        return config;
    };
