import { inject, injectable } from "inversify";
import { ProfileService as ProfileConnectorService } from "~/backend/generated/ProfileConnector/api/profile.serviceInterface";
import { Profile } from "~/backend/generated/ProfileConnector/model/profile";
import { getLogger, LoggerName } from "~/modules/logger";
import {
    ProfileAttribute,
    ProfileConnectorApis,
    ProfilesResponse,
    IdentifiersResponse,
    TraitsResponse
} from "./Definitions";
import { FindProfilesRequest } from "~/backend/generated/ProfileConnector/model/findProfilesRequest";
import { Session, sessionRTTI } from "~/modules/session";





@injectable()
export class ProfileConnectorImpl implements ProfileConnectorApis {
    readonly #profileConnectorService;

    readonly #session;

    readonly #logger;

    constructor(
        @inject("ProfileService") profileConnectorService: ProfileConnectorService,
        @inject(sessionRTTI) session: Session
    ) {
        this.#profileConnectorService = profileConnectorService;
        this.#logger = getLogger(LoggerName.ProfileConnector);
        this.#session = session;
        this.#logger.info("ProfileConnectorImpl created!");
    }

    getIdentifiers(uniqueName: string): Promise<IdentifiersResponse> {
        const token = this.#session.token;
        if (!token) {
            this.#logger.debug("getIdentifiers was called before FlexSDK.createClient");
            throw new Error("getIdentifiers was called before FlexSDK.createClient");
        }
        if (!uniqueName) {
            this.#logger.debug("getIdentifiers called without proper data");
            throw new Error("getIdentifiers called without proper data");
        }
        this.#logger.info("Get Identifiers was called");
        try {
            const response = this.#profileConnectorService.getIdentifiers(uniqueName, { token });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    getTraits(uniqueName: string): Promise<TraitsResponse> {
        const token = this.#session.token;
        if (!token) {
            this.#logger.debug("getTraits was called before FlexSDK.createClient");
            throw new Error("getTraits was called before FlexSDK.createClient");
        }
        if (!uniqueName) {
            this.#logger.debug("getTraits called without proper data");
            throw new Error("getTraits called without proper data");
        }
        this.#logger.info("GetTraits was called");
        try {
            const response = this.#profileConnectorService.getTraits(uniqueName, { token });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    findProfileByIdentifiers(findProfilesRequest: FindProfilesRequest): Promise<ProfilesResponse> {
        const token = this.#session.token;
        if (!token) {
            this.#logger.debug("findProfileByIdentifiers was called before FlexSDK.createClient");
            throw new Error("findProfileByIdentifiers was called before FlexSDK.createClient");
        }
        if (!findProfilesRequest || !findProfilesRequest.Attributes || !findProfilesRequest.UniqueName) {
            this.#logger.debug("findProfileByIdentifiers called without proper data");
            throw new Error("findProfileByIdentifiers called without proper data");
        }
        this.#logger.info("findProfileByIdentifiers was called");

        try {
            const data = new URLSearchParams();
            data.append("UniqueName", findProfilesRequest.UniqueName);
            findProfilesRequest.Attributes.forEach((attribute: ProfileAttribute) => {
                data.append("Attributes", JSON.stringify(attribute));
            });
            const response = this.#profileConnectorService.findProfileByIdentifiers(data.toString(), { token });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    findProfileByUniqueId(uniqueIdentifier: string, uniqueName: string): Promise<Profile> {
        const token = this.#session.token;
        if (!token) {
            this.#logger.debug("findProfileByUniqueId was called before FlexSDK.createClient");
            throw new Error("findProfileByUniqueId was called before FlexSDK.createClient");
        }
        if (!uniqueIdentifier || !uniqueName) {
            this.#logger.debug("findProfileByUniqueId called without proper data");
            throw new Error("findProfileByUniqueId called without proper data");
        }

        this.#logger.info("findProfileByUniqueId was called");

        try {
            const response = this.#profileConnectorService.findProfileByUniqueId(uniqueIdentifier, uniqueName, {
                token
            });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
