












import { inject, injectable } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { Plugin } from "../model/plugin";

import { PluginsService } from "./plugins.serviceInterface";

@injectable()
export class PluginsServiceImpl implements PluginsService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) simpleHttpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.simpleHttpAdapter = simpleHttpAdapter;
        this.#throwError = throwError;
        this.simpleHttpAdapter.setIsEdgeSupported(false);
    }

    




    public async fetchPlugins(options?: object): Promise<Array<Plugin>> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<Array<Plugin>>(
                `https://flex[region].twilio.com/plugins`,
                AuthenticationMethod.HostedFlex,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `PluginsService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        return response;
    }

    






    public async fetchPluginsHosted(
        queryParamName?: string,
        queryParamValue?: string,
        options?: object
    ): Promise<Array<Plugin>> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<Array<Plugin>>(
                `https://flex[region].twilio.com/plugins?${encodeURIComponent(
                    String(queryParamName)
                )}=${encodeURIComponent(String(queryParamValue))}`,
                AuthenticationMethod.HostedFlex,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `PluginsService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        return response;
    }
}
