import { inject, injectable } from "inversify";
import { VirtualAgentDataService } from "~/backend/generated/VirtualAgentData/api/virtualAgentData.serviceInterface";
import { VirtualAgentData } from "~/backend/generated/VirtualAgentData/model/virtualAgentData";
import { Session, sessionRTTI } from "~/modules/session";
import { getLogger, LoggerName } from "~/modules/logger";
import { VirtualAgentDataApi } from "~/modules/virtualAgentData/Definitions";





@injectable()
export class VirtualAgentDataImpl implements VirtualAgentDataApi {
    readonly #virtualAgentDataService;

    readonly #logger;

    readonly #session;

    constructor(
        @inject("VirtualAgentDataService") virtualAgentDataService: VirtualAgentDataService,
        @inject(sessionRTTI) session: Session
    ) {
        this.#virtualAgentDataService = virtualAgentDataService;
        this.#session = session;
        this.#logger = getLogger(LoggerName.VirtualAgentData);
        this.#logger.info("VirtualAgentImpl created!");
    }

    getV1VirtualAgentData(contextLookupSid: string): Promise<VirtualAgentData> {
        
        const token = this.#session.token;

        if (!token) {
            this.#logger.error("getV1VirtualAgentData called without token");
            throw new Error("getV1VirtualAgentData called without token");
        }

        if (!contextLookupSid) {
            this.#logger.error("getV1VirtualAgentData called without contextLookupSid");
            throw new Error("getV1VirtualAgentData called without contextLookupSid");
        }

        return this.#virtualAgentDataService.getV1VirtualAgentData("ContextLookupSid", contextLookupSid, { token });
    }
}
