import { interfaces } from "inversify";
import { RuntimeDomainOption, AccountSidOption, PublicConfig } from "~/modules/config";
import {
    ErrorCode,
    ErrorSeverity,
    ThrowAndReportErrorFunction,
    throwAndReportErrorRTTI,
    ThrowErrorFunction,
    throwErrorRTTI
} from "~/modules/error";
import { LocalStorage, localStorageRTTI, LocalStorageKeys } from "~/modules/storage";
import { XOR } from "~/utils/utilityTypes";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { PublicConfigResponse } from "~/backend/generated/Configuration/model/publicConfigResponse";
import { configurationServiceRTTI } from "~/backend/backend.rtti";
import { mapRootKeysToCamelCase } from "~/utils/mapKeys";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { getLogger, LoggerName } from "~/modules/logger";

const TWILIO_DOMAIN = ".twil.io";
const RUNTIME_DOMAIN_REGEXP = /^([a-z]+-[a-z]+-\d+)$/;

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

function sanitizeRuntimeDomain(domain: string): string {
    let sanitizedDomain = domain.trim();

    if (sanitizedDomain.endsWith("/")) {
        sanitizedDomain = sanitizedDomain.replace(/\/$/, "");
    }

    if (!sanitizedDomain.endsWith(TWILIO_DOMAIN)) {
        const parsedRuntimeDomain = sanitizedDomain.match(RUNTIME_DOMAIN_REGEXP);
        if (parsedRuntimeDomain && parsedRuntimeDomain[1]) {
            sanitizedDomain += TWILIO_DOMAIN;
        }
    }

    return sanitizedDomain;
}

function isAccountSidOption(option: XOR<AccountSidOption, RuntimeDomainOption>): option is AccountSidOption {
    return !!(option as AccountSidOption).accountSid;
}

function getPublicConfigQueryParam(option: XOR<AccountSidOption, RuntimeDomainOption>): {
    paramName: "AccountSid" | "RuntimeDomain";
    paramValue: string;
} {
    if (isAccountSidOption(option)) {
        const accountSid = (option as AccountSidOption).accountSid;
        return { paramName: "AccountSid", paramValue: accountSid };
    }

    const runtimeDomain = (option as RuntimeDomainOption).runtimeDomain;
    const sanitizedDomain = sanitizeRuntimeDomain(runtimeDomain);
    return { paramName: "RuntimeDomain", paramValue: sanitizedDomain };
}

export async function getPublicConfig(
    container: interfaces.Container,
    option: XOR<AccountSidOption, RuntimeDomainOption>
): Promise<PublicConfig> {
    const configurationService = container.get<ConfigurationService>(configurationServiceRTTI);
    const throwError = container.get<ThrowErrorFunction>(throwErrorRTTI);
    const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
    const storage = container.get<LocalStorage>(localStorageRTTI);
    const logger = getLogger(LoggerName.Config);
    const queryParam = getPublicConfigQueryParam(option);
    let responseData: PublicConfigResponse;

    try {
        responseData = await configurationService.fetchPublicConfiguration(queryParam.paramName, queryParam.paramValue);
        storage.setCachedItem(LocalStorageKeys.PublicConfig, responseData);
    } catch (err) {
        const cachedConfig = storage.getCachedItem<PublicConfigResponse>(LocalStorageKeys.PublicConfig);

        if (cachedConfig === undefined) {
            const message = `Failed to fetch public configuration: ${err}. No cache found.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
        }

        logger.warn(`Failed to fetch public configuration: ${err}. Using cache instead`);
        responseData = cachedConfig;
    }

    if (!(responseData && responseData.configurations && responseData.configurations.length)) {
        throwError(
            ErrorCode.SDK,
            { ...metadata, severity: ErrorSeverity.Error },
            "Invalid response from public configuration endpoint"
        );
    }

    return mapRootKeysToCamelCase(responseData.configurations[0]) as PublicConfig;
}
