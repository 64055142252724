import { interfaces } from "inversify";

import { Role } from "~/modules/auth";
import { Session, sessionRTTI } from "~/modules/session";
import { ErrorCode, ErrorSeverity, ThrowAndReportErrorFunction, throwAndReportErrorRTTI } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

export const requiredRole = (container: interfaces.Container) => (role: Role) => {
    return function decorator(target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        const session = container.get<Session>(sessionRTTI);

        
        descriptor.value = function wrappedMethod(...args: Array<unknown>) {
            const currentRoles = session.roles;
            if (!currentRoles.includes(role)) {
                const wrongRole: string = currentRoles.length ? currentRoles.toString() : "no role";

                const metadata = {
                    module: extractModuleFromPath(__dirname),
                    severity: ErrorSeverity.Error,
                    source: extractFileNameFromPath(__filename)
                };
                const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
                throwAndReportError(
                    ErrorCode.Forbidden,
                    metadata,
                    `Expected the role ${role}, but got ${wrongRole} instead.`
                );
            }

            const result = originalMethod.apply(this, args);
            return result;
        };
    };
};
