import { injectable, inject } from "inversify";
import EventEmitter from "events";
import { Role, AuthenticatorFactory, authenticatorFactoryRTTI } from "~/modules/auth";
import { AccountConfigProvider, accountConfigProviderRTTI, AccountConfigData } from "~/modules/config";
import { sessionOptionsRTTI, Session, SessionOptions, SessionEvent } from "~/modules/session";
import { Twilsock, twilsockRTTI, TwilsockEvent } from "~/modules/websocket";
import { InternalError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { Emitter } from "~/modules/events";
import { getCurrentDate } from "~/utils/getCurrentDate";

const TOKEN_ABOUT_TO_EXPIRE_MINUTES = 1;
const VERIFICATION_INTERVAL_MS = 15000;

@injectable()
export class SessionImpl implements Session {
    readonly #authFactory: AuthenticatorFactory;

    #token: string;

    #tokenExpiration: Date;

    readonly #connection: Twilsock;

    readonly #options: SessionOptions;

    readonly #accountConfigProvider: AccountConfigProvider;

    #accountConfigData: AccountConfigData;

    #roles: Array<Role> = [];

    #identity: string;

    #isActive: boolean = true;

    readonly #logger: Logger;

    #needsToAutoUpdateToken: boolean;

    readonly #emitter: Emitter;

    #verificationInterval: ReturnType<typeof setInterval>;

    constructor(
        @inject(twilsockRTTI) connection: Twilsock,
        @inject(sessionOptionsRTTI) options: SessionOptions,
        @inject(authenticatorFactoryRTTI) authFactory: AuthenticatorFactory,
        @inject(accountConfigProviderRTTI) accountConfigProvider: AccountConfigProvider
    ) {
        this.#connection = connection;
        this.#options = options;
        this.#accountConfigProvider = accountConfigProvider;
        this.#authFactory = authFactory;
        this.#logger = getLogger(LoggerName.Session);
        this.#logger.debug("Session constructed");
        this.#emitter = new EventEmitter();
    }

    async init(token: string): Promise<void> {
        this.#logger.debug("will initialize session with token: ", token);
        this.#logger.debug("will update token: ", this.#options.autoUpdateToken);

        this.#token = token;
        await this.#connection.connect(token);

        
        if (this.#options.autoUpdateToken) {
            this.#connection.addListener(TwilsockEvent.TokenAboutToExpire, this.#handleTokenAboutToExpire);
        }

        
        this.#accountConfigData = await this.#accountConfigProvider(token);

        const accountSid = this.#accountConfigData.accountSid;

        const auth = this.#authFactory(accountSid);
        const tokenData = await auth.validateToken(this.#token);
        this.#roles = tokenData.roles;
        this.#tokenExpiration = tokenData.dateExpired;
        this.#identity = tokenData.identity;

        
        if (this.#options.autoUpdateToken) {
            this.#needsToAutoUpdateToken = this.#needsToAutoUpdateToken || this.#isTokenCloseToExpiry();
            this.#verificationInterval = setInterval(() => this.#verifyToken(), VERIFICATION_INTERVAL_MS);
        }

        if (this.#needsToAutoUpdateToken) {
            await this.#autoUpdateToken();
        }

        return Promise.resolve();
    }

    async updateToken(token: string): Promise<void> {
        await this.#connection.updateToken(token);
        this.#token = token;
        this.#logger.debug("new token set");
    }

    #verifyToken = async () => {
        if (this.#isTokenCloseToExpiry()) {
            await this.#autoUpdateToken();
        }
    };

    #isTokenCloseToExpiry = () => {
        
        const now = getCurrentDate();
        const tokenTtlMinutes = (this.#tokenExpiration.getTime() - now.getTime()) / (60 * 1000);

        return tokenTtlMinutes <= TOKEN_ABOUT_TO_EXPIRE_MINUTES;
    };

    readonly #handleTokenAboutToExpire = async () => {
        if (this.#accountConfigData) {
            await this.#autoUpdateToken();
        } else {
            this.#needsToAutoUpdateToken = true;
        }
    };

    #autoUpdateToken = async () => {
        this.#logger.debug("Auto-updating token");

        const accountSid = this.#accountConfigData.accountSid;
        if (!accountSid) {
            throw new InternalError("Account sid not set");
        }

        let newToken: string | undefined;
        let newTokenDateExpired: Date | undefined;
        const auth = this.#authFactory(accountSid);
        try {
            const tokenRefreshResult = await auth.refreshToken(this.token);
            newTokenDateExpired = tokenRefreshResult.dateExpired;
            if (newTokenDateExpired.getTime() === this.#tokenExpiration.getTime()) {
                this.#logger.warn("Token expiration not extended, because max lifetime reached");
                this.#emitter.emit(SessionEvent.TokenMaxLifetimeReached, tokenRefreshResult.dateExpired);
            }

            newToken = tokenRefreshResult.token;
        } catch (e) {
            this.#logger.error("Failed to refresh token", e);
            this.#emitter.emit(SessionEvent.TokenAutoUpdateFailed);
        }

        if (!this.#isActive) {
            this.#logger.trace("AutoUpdateToken, session destroyed after refreshToken");
            return;
        }

        if (newToken && newTokenDateExpired) {
            try {
                await this.updateToken(newToken);
                this.#tokenExpiration = newTokenDateExpired;
                this.#logger.info("Token auto-updated");
            } catch (e) {
                this.#logger.error("Failed to auto-update token", e);
                this.#emitter.emit(SessionEvent.TokenAutoUpdateFailed);
            }
        }
    };

    async destroy(): Promise<void> {
        if (this.#verificationInterval) {
            clearInterval(this.#verificationInterval);
        }
        this.#isActive = false;
        this.#connection.removeListener(TwilsockEvent.TokenAboutToExpire, this.#handleTokenAboutToExpire);
        await this.#connection.destroy();
    }

    get token(): string {
        return this.#token;
    }

    get roles(): Array<Role> {
        return this.#roles;
    }

    addListener(eventName: SessionEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: SessionEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }
}
