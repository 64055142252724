import { InternalError } from "~/modules/error";
import { RetryOptions } from "~/utils/retry/RetryOptions";

function calculateNextDelay(retryCount: number, initialDelay: number, backoff: "exponential" | "linear"): number {
    if (backoff === "exponential") {
        return 1.5 ** retryCount * initialDelay;
    }
    return (retryCount + 1) * initialDelay;
}

export async function retry<T>(options: RetryOptions<T>): Promise<T> {
    let retryCount = 0;
    const maxDelay = 15 * 60 * 1000; 
    const initialDelay = typeof options.initialDelay !== "undefined" ? options.initialDelay : 100;
    const maxAttempts = typeof options.maxAttempts !== "undefined" ? options.maxAttempts : 3;
    const backoff = options.backoff || "exponential";
    let errorToReturn;

    if (maxAttempts < 1) {
        throw new InternalError("The value of max retry attempts has to be bigger than 0");
    }

    do {
        try {
            if (options.logger && retryCount > 0) {
                options.logger.debug("Retry attempt: ", retryCount);
            }

            
            return await options.functionToRetry();
        } catch (error) {
            if (!options.retryCondition(error)) {
                return Promise.reject(error);
            }
            if (retryCount >= maxAttempts - 1) {
                errorToReturn = error;
                break;
            }
        }
        let delay = calculateNextDelay(retryCount, initialDelay, backoff);

        
        if (delay > maxDelay) {
            delay = maxDelay;
        }

        
        await new Promise((resolve) => setTimeout(resolve, delay));
    } while (retryCount++ < maxAttempts - 1);

    return Promise.reject(errorToReturn);
}
