import { interfaces } from "inversify";

import { PluginsServiceImpl } from "./api/plugins.service";
import { PluginsService } from "./api/plugins.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<PluginsService>("PluginsService").to(PluginsServiceImpl).inSingletonScope();
    }
}
