

import { injectable, inject } from "inversify";
import { BodyInit } from "node-fetch";
import {
    ErrorCode,
    ErrorSeverity,
    InternalError,
    ThrowErrorFromResponseFunction,
    throwErrorFromResponseRTTI,
    ThrowErrorFunction,
    throwErrorRTTI
} from "~/modules/error";
import { sessionRTTI, Session } from "~/modules/session";
import { EnvironmentConfig, environmentConfigRTTI } from "~/modules/config";
import { extractFileNameFromPath } from "~/utils/extractFromPath";
import { retry as performApiCallWithRetry } from "~/utils/retry/retry";
import { defaultRetryConditionOnFlexSdkError } from "~/utils/defaultRetryConditionOnFlexSdkError";
import { AuthenticationMethod, makeAuthenticationHeaders } from "../commons/authenticationMethods";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { buildRegionalHost, buildRegionalHostWithEdge } from "~/utils/regionUtil";

@injectable()
export class SimpleHttpAdapterImpl implements HttpAdapter {
    readonly #session: Session;

    readonly #envConfig: EnvironmentConfig;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromResponse: ThrowErrorFromResponseFunction;

    #isEdgeSupported: Boolean;

    constructor(
        @inject(sessionRTTI) session: Session,
        @inject(environmentConfigRTTI) envConfig: EnvironmentConfig,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction,
        @inject(throwErrorFromResponseRTTI) throwErrorFromResponse: ThrowErrorFromResponseFunction
    ) {
        this.#session = session;
        this.#envConfig = envConfig;
        this.#throwError = throwError;
        this.#throwErrorFromResponse = throwErrorFromResponse;
    }

    public get<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        options?: { [key: string]: unknown; headers?: object }
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () => this.#performNetworkCallOnce<T>(url, "GET", authMethod, undefined, options),
            retryCondition: defaultRetryConditionOnFlexSdkError
        });
    }

    public post<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        options?: { [key: string]: unknown; headers?: object },
        requestContentType?: string
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () =>
                this.#performNetworkCallOnce<T>(url, "POST", authMethod, body, options, requestContentType),
            retryCondition: defaultRetryConditionOnFlexSdkError
        });
    }

    public put<T>(
        url: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        requestContentType?: string
    ): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () =>
                this.#performNetworkCallOnce<T>(url, "PUT", authMethod, body, undefined, requestContentType),
            retryCondition: defaultRetryConditionOnFlexSdkError
        });
    }

    public delete<T>(url: string, authMethod?: AuthenticationMethod): Promise<T> {
        return performApiCallWithRetry({
            functionToRetry: () => this.#performNetworkCallOnce<T>(url, "DELETE", authMethod),
            retryCondition: defaultRetryConditionOnFlexSdkError
        });
    }

    public setIsEdgeSupported(isEdgeSupported: Boolean = false): void {
        this.#isEdgeSupported = isEdgeSupported;
    }

    #getEnvironmentSpecificUrl(url: string): string {
        const region = this.#envConfig.region || "";
        const edge = this.#envConfig.edge || "";

        return url.replace(
            "[region]",
            this.#isEdgeSupported ? buildRegionalHostWithEdge(region, edge) : buildRegionalHost(region)
        );
    }

    #getToken = (token?: unknown) => {
        if (!token) {
            return this.#session.token;
        }

        if (typeof token === "string") {
            return token;
        }

        throw new InternalError("No token in request body");
    };

    #mapStatusCodeToFlexSdkErrorCode = (statusCode: number): ErrorCode | undefined => {
        const HTTPS_STATUS_CODE_TOO_MANY_REQUESTS = 429;
        const HTTPS_STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

        let errorCode;
        if (statusCode === HTTPS_STATUS_CODE_TOO_MANY_REQUESTS) {
            errorCode = ErrorCode.TooManyRequests;
        } else if (statusCode >= HTTPS_STATUS_CODE_INTERNAL_SERVER_ERROR) {
            errorCode = ErrorCode.Unknown;
        }
        return errorCode;
    };

    #performNetworkCallOnce = async <T>(
        url: string,
        method: string,
        authMethod?: AuthenticationMethod,
        body?: BodyInit,
        options?: { [key: string]: unknown; headers?: object },
        requestContentType?: string
    ): Promise<T> => {
        const environmentSpecificUrl = this.#getEnvironmentSpecificUrl(url);
        let response: Response;

        try {
            let headers = new Headers({
                "Content-Type": requestContentType || "application/json"
            });

            if (authMethod) {
                headers = makeAuthenticationHeaders(authMethod, this.#getToken(options?.token), requestContentType);
            }

            const optionHeader: object = options?.headers || {};
            Object.entries(optionHeader).forEach(([key, value]) => {
                headers.append(key, value);
            });

            response = await fetch(environmentSpecificUrl, {
                headers,
                method,
                body
            });
        } catch (e) {
            this.#throwError(ErrorCode.NetworkError, undefined, undefined, e);
        }

        if (!response.ok) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            const flexSdkErrorCode = this.#mapStatusCodeToFlexSdkErrorCode(response.status);

            if (flexSdkErrorCode) {
                this.#throwError(flexSdkErrorCode, metadata);
            }
            await this.#throwErrorFromResponse(response, metadata);
        }
        const result = await response.json();

        return result as Promise<T>;
    };
}
