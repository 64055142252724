import { interfaces } from "inversify";
import { FeaturesConfig } from "~/backend/generated/Features/model/featuresConfig";
import { FeaturesProvider, featuresProviderRTTI } from "~/modules/config";

export const getFeaturesConfig =
    (container: interfaces.Container) =>
    async (token: string): Promise<FeaturesConfig> => {
        const configProvider = container.get<FeaturesProvider>(featuresProviderRTTI);
        const config = await configProvider(token);
        return config;
    };
