












import { inject, injectable, named } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI, httpAdapterTag } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { IdpUrlResponse } from "../model/idpUrlResponse";
import { SSOLoginRequestBody } from "../model/sSOLoginRequestBody";
import { Token } from "../model/token";
import { TokenData } from "../model/tokenData";
import { TokenRefreshResult } from "../model/tokenRefreshResult";

import { FederatedAuthService } from "./federatedAuth.serviceInterface";

@injectable()
export class FederatedAuthServiceImpl implements FederatedAuthService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) @named(httpAdapterTag) httpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.httpAdapter = httpAdapter;
        this.#throwError = throwError;
        this.httpAdapter.setIsEdgeSupported(true);
    }

    






    public async getIdpUrl(accountSid: string, sSOLoginRequestBody: SSOLoginRequestBody): Promise<IdpUrlResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (sSOLoginRequestBody === null || sSOLoginRequestBody === undefined) {
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter sSOLoginRequestBody was null or undefined."
            );
        }

        let response;
        try {
            response = await this.httpAdapter.post<IdpUrlResponse>(
                `https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/authenticate`,
                undefined,
                sSOLoginRequestBody
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<IdpUrlResponse>(response);
        return processedResponse;
    }

    






    public async refreshToken(accountSid: string, token: Token): Promise<TokenRefreshResult> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (token === null || token === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter token was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<TokenRefreshResult>(
                `https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Tokens/refresh`,
                undefined,
                token
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<TokenRefreshResult>(response);
        return processedResponse;
    }

    






    public async validateToken(accountSid: string, token: Token, options?: object): Promise<TokenData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (token === null || token === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter token was null or undefined.");
        }

        let response;
        try {
            response = await this.httpAdapter.post<TokenData>(
                `https://iam[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Tokens/validate`,
                AuthenticationMethod.Basic,
                token,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `FederatedAuthService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<TokenData>(response);
        return processedResponse;
    }
}
