import "reflect-metadata";

import { Container } from "inversify";

import { authModuleContainer } from "./modules/auth/auth.ioc";
import { sessionModuleContainer } from "./modules/session/session.ioc";
import { clientModuleContainer } from "./modules/client/client.ioc";
import { errorModuleContainer } from "./modules/error/error.ioc";
import { configModuleContainer } from "./modules/config/config.ioc";
import { websocketModuleContainer } from "./modules/websocket/websocket.ioc";
import { rbacModuleContainer } from "./modules/rbac/rbac.ioc";
import { telemetryModuleContainer } from "./modules/telemetry/telemetry.ioc";
import { telemetrySdkClientModuleContainer } from "./modules/telemetrySdkClient/telemetrySdkClient.ioc";
import { storageModuleContainer } from "~/modules/storage/storage.ioc";
import { queueStatsModuleContainer } from "~/modules/queueStats/queuestats.ioc";
import { workerModuleContainer } from "~/modules/worker/worker.ioc";
import { withContainer } from "./backend/openApi.container";
import { insightsModule } from "./modules/insights/insights.ioc";
import { InsightsApis } from "./modules/insights/historicalReporting/Definitions";
import { InsightsImpl } from "./modules/insights/historicalReporting/InsightsImpl";
import { profileConnectorModule } from "./modules/profileConnector/profileConnector.ioc";
import { virtualAgentModule } from "./modules/virtualAgentData/virtualAgent.ioc";

export function createContainer(): Container {
    const container = new Container();
    container.load(authModuleContainer);
    container.load(configModuleContainer);
    container.load(sessionModuleContainer);
    container.load(clientModuleContainer);
    container.load(errorModuleContainer);
    container.load(websocketModuleContainer);
    container.load(rbacModuleContainer);
    container.load(telemetryModuleContainer);
    container.load(telemetrySdkClientModuleContainer);
    container.load(storageModuleContainer);
    container.load(queueStatsModuleContainer);
    container.load(workerModuleContainer);
    container.load(insightsModule);
    container.load(profileConnectorModule);
    container.load(virtualAgentModule);
    withContainer(container);
    return container;
}

export const fetchInsightsContainer = (): InsightsApis => {
    const container = createContainer();
    return container.resolve(InsightsImpl);
};

let singletonContainer: Container;
export function getSingletonContainer(): Container {
    if (!singletonContainer) {
        singletonContainer = createContainer();
    }

    return singletonContainer;
}
