import { ContainerModule, interfaces } from "inversify";
import { workerConfigProviderRTTI, setWorkerConfigRTTI } from "./worker.rtti";
import { WorkerProvider, WorkerConfigDataSetter, WorkerConfigResponse } from "~/modules/worker";

import { WorkerProviderImpl } from "~/modules/worker/WorkerProvider/WorkerProviderImpl/WorkerProviderImpl";
import { setWorkerConfig } from "~/modules/worker/WorkerConfigDataSetter/setWorkerConfig";

export const workerModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<WorkerProvider>(workerConfigProviderRTTI).toProvider<WorkerConfigResponse>(WorkerProviderImpl);
    bind<WorkerConfigDataSetter>(setWorkerConfigRTTI).toDynamicValue((context: interfaces.Context) =>
        setWorkerConfig(context.container)
    );
});
