import { interfaces } from "inversify";

import { FederatedAuthServiceImpl } from "./api/federatedAuth.service";
import { FederatedAuthService } from "./api/federatedAuth.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<FederatedAuthService>("FederatedAuthService").to(FederatedAuthServiceImpl).inSingletonScope();
    }
}
