import { interfaces } from "inversify";
import { ErrorCode } from "~/modules/error/ErrorCode/ErrorCodes";
import { getPublicErrorCode, isInternalErrorCode, isKnownErrorCode } from "~/modules/error/ErrorCode/ErrorCodeHelper";
import { FlexSdkError } from "~/modules/error/FlexSdkError/FlexSdkError";

import { InternalErrorCode } from "~/modules/error/ErrorCode//InternalErrorCodes";
import { reportError } from "./reportError";
import { FlexSdkErrorMetadata } from "~/modules/error/FlexSdkErrorMetadata/FlexSdkErrorMetadata";

export interface ErrorResponse {
    code?: number;
    message?: string;
}

function createFlexSdkError(
    errorCode: number,
    metadata?: FlexSdkErrorMetadata,
    message?: string,
    error?: Error
): FlexSdkError {
    
    if (error instanceof FlexSdkError) {
        return error;
    }

    if (!errorCode || !isKnownErrorCode(errorCode)) {
        console.warn("Unknown error code"); 
    }

    let publicErrorCode = errorCode;
    if (isInternalErrorCode(errorCode)) {
        publicErrorCode = getPublicErrorCode(errorCode as InternalErrorCode);
    }

    return new FlexSdkError(publicErrorCode, metadata, message, error);
}



export function throwFlexSdkError(
    errorCode: number,
    metadata?: FlexSdkErrorMetadata,
    message?: string,
    error?: Error
): never {
    throw createFlexSdkError(errorCode, metadata, message, error);
}

export function throwAndReportFlexSdkError(
    container: interfaces.Container,
    errorCode: number,
    metadata?: FlexSdkErrorMetadata,
    message?: string,
    error?: Error
): never {
    const errorToThrow = createFlexSdkError(errorCode, metadata, message, error);

    reportError(container, errorToThrow);
    throwFlexSdkError(errorCode, metadata, message, errorToThrow);
}

export function reThrowFlexSdkErrorWithCustomMessage(error: FlexSdkError, customMessage: string): never {
    throw new FlexSdkError(error.code, error.metadata, customMessage, error.wrappedError);
}

export function throwFlexSdkErrorFromErrorResponse(
    errorResponse: ErrorResponse,
    metadata?: FlexSdkErrorMetadata
): never {
    if (errorResponse instanceof FlexSdkError) {
        throw errorResponse;
    }
    const errorCode = errorResponse.code ? errorResponse.code : ErrorCode.Unknown;
    throwFlexSdkError(errorCode, metadata, errorResponse.message);
}

export async function throwFlexSdkErrorFromResponse(
    response: Response,
    metadata?: FlexSdkErrorMetadata,
    message?: string
): Promise<never> {
    const errorResponse: ErrorResponse = await response.json();
    errorResponse.message = message || errorResponse.message;
    throwFlexSdkErrorFromErrorResponse(errorResponse, metadata);
}
