import { EventEmitter } from "events";
import type { InsightsUserRoles } from "~/backend/generated/Insights/model/insightsUserRoles";

import type { SessionSuccess } from "~/backend/generated/Insights/model/sessionSuccess";

export type { InsightsUserRoles } from "~/backend/generated/Insights/model/insightsUserRoles";
export type { SessionSuccess } from "~/backend/generated/Insights/model/sessionSuccess";
export const insightsRTTI = Symbol.for("insightsImpl");




export interface GDTokenEvents {
    TokenUpdated: string;
    TokenUpdateFailed: string;
}




export interface InsightsApis {
    readonly events: GDTokenEvents;
    eventEmitter: EventEmitter;
    getInsightsRolesForUser: () => Promise<InsightsUserRoles>;
    generateGoodDataSession: () => Promise<SessionSuccess>;
    destroy: () => void;
}
