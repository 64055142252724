import { ContainerModule, interfaces } from "inversify";
import {
    throwAndReportErrorRTTI,
    throwErrorFromErrorResponseRTTI,
    throwErrorFromResponseRTTI,
    reThrowErrorWithCustomMessageRTTI
} from "~/modules/error/error.rtti";
import {
    ThrowAndReportErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    ThrowErrorFromResponseFunction,
    ReThrowErrorWithCustomMessageFunction,
    ThrowErrorFunction
} from "~/modules/error/ThrowError/ThrowErrorFunctions";
import {
    throwAndReportFlexSdkError,
    throwFlexSdkErrorFromErrorResponse,
    throwFlexSdkErrorFromResponse,
    reThrowFlexSdkErrorWithCustomMessage,
    ErrorResponse,
    throwFlexSdkError
} from "~/modules/error/ThrowError/ErrorHelper";
import { FlexSdkErrorMetadata } from "~/modules/error/FlexSdkErrorMetadata/FlexSdkErrorMetadata";
import { throwErrorRTTI } from "~/modules/error";

export const errorModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<ThrowErrorFunction>(throwErrorRTTI)
        .toDynamicValue(() => {
            return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error) => {
                return throwFlexSdkError(errorCode, metadata, message, error);
            };
        })
        .inSingletonScope();
    bind<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error) => {
                return throwAndReportFlexSdkError(context.container, errorCode, metadata, message, error);
            };
        })
        .inSingletonScope();
    bind<ThrowErrorFromErrorResponseFunction>(throwErrorFromErrorResponseRTTI)
        .toDynamicValue(() => {
            return (errorResponse: ErrorResponse, metadata?: FlexSdkErrorMetadata) => {
                return throwFlexSdkErrorFromErrorResponse(errorResponse, metadata);
            };
        })
        .inSingletonScope();
    bind<ThrowErrorFromResponseFunction>(throwErrorFromResponseRTTI)
        .toDynamicValue(() => {
            return (response: Response, metadata?: FlexSdkErrorMetadata, message?: string) => {
                return throwFlexSdkErrorFromResponse(response, metadata, message);
            };
        })
        .inSingletonScope();
    bind<ReThrowErrorWithCustomMessageFunction>(reThrowErrorWithCustomMessageRTTI).toConstantValue(
        reThrowFlexSdkErrorWithCustomMessage
    );
});
