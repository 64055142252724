












import { inject, injectable, named } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI, httpAdapterTag } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { InsightsUserRoles } from "../model/insightsUserRoles";
import { SessionSuccess } from "../model/sessionSuccess";

import { InsightsService } from "./insights.serviceInterface";

@injectable()
export class InsightsServiceImpl implements InsightsService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) @named(httpAdapterTag) httpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.httpAdapter = httpAdapter;
        this.#throwError = throwError;
        this.httpAdapter.setIsEdgeSupported();
    }

    





    public async getGoodDataSessionForUser(body?: object, options?: object): Promise<SessionSuccess> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.post<SessionSuccess>(
                `https://flex-api[region].twilio.com/v1/Insights/Session`,
                AuthenticationMethod.Basic,
                body,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InsightsService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<SessionSuccess>(response);
        return processedResponse;
    }

    




    public async getInsightsRolesForUser(options?: object): Promise<InsightsUserRoles> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.get<InsightsUserRoles>(
                `https://flex-api[region].twilio.com/v1/Insights/UserRoles`,
                AuthenticationMethod.Basic,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InsightsService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<InsightsUserRoles>(response);
        return processedResponse;
    }
}
