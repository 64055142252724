import type { Profile } from "~/backend/generated/ProfileConnector/model/profile";
import type { ProfilesResponse } from "~/backend/generated/ProfileConnector/model/profilesResponse";
import type { FindProfilesRequest } from "~/backend/generated/ProfileConnector/model/findProfilesRequest";
import type { IdentifiersResponse } from "~/backend/generated/ProfileConnector/model/identifiersResponse";
import type { TraitsResponse } from "~/backend/generated/ProfileConnector/model/traitsResponse";



export type { Profile } from "~/backend/generated/ProfileConnector/model/profile";



export type { ProfilesResponse } from "~/backend/generated/ProfileConnector/model/profilesResponse";
export type { ProfileContact } from "~/backend/generated/ProfileConnector/model/profileContact";
export type { ProfileAttribute } from "~/backend/generated/ProfileConnector/model/profileAttribute";
export type { ProfileAddress } from "~/backend/generated/ProfileConnector/model/profileAddress";
export type { FindProfilesRequest } from "~/backend/generated/ProfileConnector/model/findProfilesRequest";
export type { IdentifiersResponse } from "~/backend/generated/ProfileConnector/model/identifiersResponse";
export type { TraitsResponse } from "~/backend/generated/ProfileConnector/model/traitsResponse";
export type { Identifier } from "~/backend/generated/ProfileConnector/model/identifier";
export type { Trait } from "~/backend/generated/ProfileConnector/model/trait";




export type { RankedProfile } from "~/backend/generated/ProfileConnector/model/rankedProfile";

export const profileConnectorRTTI = Symbol.for("profileConnectorImpl");




export interface ProfileConnectorApis {
    findProfileByUniqueId(uniqueIdentifier: string, uniqueName: string): Promise<Profile>;
    findProfileByIdentifiers(findProfilesRequest: FindProfilesRequest): Promise<ProfilesResponse>;
    getIdentifiers(uniqueName: string): Promise<IdentifiersResponse>;
    getTraits(uniqueName: string): Promise<TraitsResponse>;
}
