import { interfaces } from "inversify";

import { VirtualAgentDataServiceImpl } from "./api/virtualAgentData.service";
import { VirtualAgentDataService } from "./api/virtualAgentData.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container
            .bind<VirtualAgentDataService>("VirtualAgentDataService")
            .to(VirtualAgentDataServiceImpl)
            .inSingletonScope();
    }
}
