import { interfaces } from "inversify";
import { workerConfigServiceRTTI } from "~/backend/backend.rtti";
import { WorkerConfigService } from "~/backend/generated/Worker/api/workerConfig.serviceInterface";
import { ErrorCode, ErrorSeverity, ThrowAndReportErrorFunction, throwAndReportErrorRTTI } from "~/modules/error";
import { getLogger, LoggerName } from "~/modules/logger";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { WorkerConfigResponse } from "../WorkerProvider";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const WorkerProviderImpl =
    ({ container }: interfaces.Context) =>
    async (sessionToken: string, workerConfig: string): Promise<WorkerConfigResponse> => {
        const workerConfigService = container.get<WorkerConfigService>(workerConfigServiceRTTI);
        const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
        const logger = getLogger(LoggerName.Config);

        let workerData: WorkerConfigResponse;

        try {
            workerData = await workerConfigService.updateWorkerConfig(workerConfig, { token: sessionToken });
        } catch (err) {
            logger.warn(`Failed to update worker config: ${err}`);
            const message = `Failed to update worker config: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
        }
        return workerData;
    };
