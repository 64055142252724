import { injectable } from "inversify";
import { Logger, LoggerName, getLogger } from "~/modules/logger";
import { LocalStorage, CachedValue } from "./LocalStorage";
import { LocalStorageKeys } from "./LocalStorageKeys";

const msInHour = 60 * 60 * 1000;
const TTL = 24 * msInHour;

@injectable()
export class LocalStorageImpl implements LocalStorage {
    readonly #logger: Logger;

    constructor() {
        this.#logger = getLogger(LoggerName.Storage);
    }

    getCachedItem<T = unknown>(key: LocalStorageKeys): undefined | T {
        const serializedItem = localStorage.getItem(key);

        if (!serializedItem) {
            this.#logger.debug(`The ${key} cache item is not available`);
            return undefined;
        }

        const item: CachedValue<T> = JSON.parse(serializedItem);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            this.#logger.debug(`The ${key} cache item has expired`);
            return undefined;
        }

        return item.value;
    }

    setCachedItem(key: LocalStorageKeys, value: unknown) {
        const expiry = new Date().getTime() + TTL;

        const item: CachedValue = {
            value,
            expiry
        };

        try {
            localStorage.setItem(key, JSON.stringify(item));
        } catch (err) {
            
            this.#logger.error(err);
        }

        this.#logger.debug(`Cached ${key}`);
    }
}
