import { interfaces } from "inversify";

import { QuequeStatsServiceImpl } from "./api/quequeStats.service";
import { QuequeStatsService } from "./api/quequeStats.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<QuequeStatsService>("QuequeStatsService").to(QuequeStatsServiceImpl).inSingletonScope();
    }
}
