export const twilsockAdapterRTTI = Symbol.for("TwilsockAdapter");
export const httpAdapterRTTI = Symbol.for("HttpAdapter");
export const telemetryServiceRTTI = "TelemetryService";
export const workspaceStatsServiceRTTI = "WorkspaceStatsService";
export const configurationServiceRTTI = "ConfigurationService";
export const featuresServiceRTTI = "FeaturesService";
export const federatedAuthServiceRTTI = "FederatedAuthService";
export const workerConfigServiceRTTI = "WorkerConfigService";
export const httpAdapterTag = "HttpAdapter";
export const pluginsServiceRTTI = "PluginsService";
export const queueStatsServiceRTTI = "QuequeStatsService";
