import { interfaces } from "inversify";
import { AccountConfigData } from "~/backend/generated/Configuration/model/accountConfigData";
import { AccountConfigProvider } from "../AccountConfigProvider/AccountConfigProvider";
import { AccountConfigOptions } from "../AccountConfigOptions/AccountConfigOptions";
import { accountConfigProviderRTTI, configDataContainerRTTI } from "../config.rtti";
import { ConfigDataContainer } from "../AccountConfig/ConfigDataContainer";

export const getAccountConfigData =
    (container: interfaces.Container) =>
    async (sessionToken: string, options?: AccountConfigOptions): Promise<AccountConfigData> => {
        const configProvider = container.get<AccountConfigProvider>(accountConfigProviderRTTI);
        const dataContainer = container.get<ConfigDataContainer<AccountConfigData>>(configDataContainerRTTI);

        try {
            return dataContainer.get();
        } catch (e) {
            return configProvider(sessionToken, options);
        }
    };
