import { interfaces } from "inversify";
import { Plugin } from "../PluginsProvider";
import { ThrowAndReportErrorFunction, throwAndReportErrorRTTI, ErrorSeverity, ErrorCode } from "~/modules/error";
import { getLogger, LoggerName } from "~/modules/logger";
import { pluginsServiceRTTI } from "~/backend/backend.rtti";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { PluginsService } from "~/backend/generated/Plugins/api/plugins.serviceInterface";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const pluginsProviderImpl =
    ({ container }: interfaces.Context) =>
    async (sessionToken: string, queryParams?: string): Promise<Array<Plugin>> => {
        const pluginsService = container.get<PluginsService>(pluginsServiceRTTI);
        const throwAndReportError = container.get<ThrowAndReportErrorFunction>(throwAndReportErrorRTTI);
        const logger = getLogger(LoggerName.Config);

        let pluginsData: Array<Plugin>;

        try {
            if (queryParams) {
                const [queryProp, queryValue] = queryParams.split("=");
                pluginsData = await pluginsService.fetchPluginsHosted(queryProp, queryValue, { token: sessionToken });
            } else {
                pluginsData = await pluginsService.fetchPlugins({ token: sessionToken });
            }
        } catch (err) {
            logger.warn(`Failed to fetch plugins: ${err}`);
            const message = `Failed to fetch plugins: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
        }
        return pluginsData;
    };
