export type {
    Logger,
    LoggerOptions,
    LoggerLevel,
    LoggerLevelNumbers,
    Loglevel,
    LoglevelMethodName
} from "./Logger/Logger";

export { LogEntry, Severity } from "./Logger/LogEntry";
export type { LoggerFactory } from "./LoggerFactory/LoggerFactory";
export { LoggerName, TelemetryLoggerName } from "./Logger/LoggerName";
export { getLogger, getDefaultLoggerOptions } from "./LoggerFactory/getLogger";

