import { ContainerModule, interfaces } from "inversify";
import {
    queueStatsChannelsProviderRTTI,
    getQueueStatsChannelsRTTI,
    queueStatsQueuesProviderRTTI,
    getQueueStatsQueuesRTTI
} from "./queueStats.rtti";
import {
    QueueStatsChannelsProvider,
    QueueStatsChannelsDataGetter,
    ChannelData,
    QueueStatsQueuesDataGetter,
    QueueStatsQueuesProvider,
    QueuesData
} from "~/modules/queueStats";

import { QueueStatsChannelsProviderImpl } from "~/modules/queueStats/QueueStatsChannelsProvider/QueueStatsChannelsProviderImpl/QueueStatsChannelsProviderImpl";
import { QueueStatsQueuesProviderImpl } from "~/modules/queueStats/QueueStatsQueuesProvider/QueueStatsChannelsProviderImpl/QueueStatsQueuesProviderImpl";
import { getQueueStatsChannels } from "~/modules/queueStats/QueueStatsChannelsDataGetter/getQueueStatsChannels";
import { getQueueStatsQueues } from "~/modules/queueStats/QueueStatsQueuesDataGetter/getQueueStatsQueues";

export const queueStatsModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    
    bind<QueueStatsChannelsProvider>(queueStatsChannelsProviderRTTI).toProvider<ChannelData | string>(
        QueueStatsChannelsProviderImpl
    );
    bind<QueueStatsChannelsDataGetter>(getQueueStatsChannelsRTTI).toDynamicValue((context: interfaces.Context) =>
        getQueueStatsChannels(context.container)
    );

    bind<QueueStatsQueuesProvider>(queueStatsQueuesProviderRTTI).toProvider<QueuesData>(QueueStatsQueuesProviderImpl);
    bind<QueueStatsQueuesDataGetter>(getQueueStatsQueuesRTTI).toDynamicValue((context: interfaces.Context) =>
        getQueueStatsQueues(context.container)
    );
});
