import { interfaces } from "inversify";
import { Role } from "~/modules/auth";
import { Session, sessionRTTI } from "~/modules/session";

export const hasRole =
    (container: interfaces.Container) =>
    (role: Role): boolean => {
        const session = container.get<Session>(sessionRTTI);
        const userRoles = session.roles;
        return userRoles.includes(role);
    };
