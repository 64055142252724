












import { inject, injectable, named } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI, isDownstreamErrorCode } from "~/modules/error";
import { httpAdapterRTTI, httpAdapterTag } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { AccountConfigData } from "../model/accountConfigData";
import { PublicConfigResponse } from "../model/publicConfigResponse";

import { ConfigurationService } from "./configuration.serviceInterface";

@injectable()
export class ConfigurationServiceImpl implements ConfigurationService {
    private httpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(httpAdapterRTTI) @named(httpAdapterTag) httpAdapter: HttpAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.httpAdapter = httpAdapter;
        this.#throwError = throwError;
        this.httpAdapter.setIsEdgeSupported(true);
    }

    






    public async fetchConfiguration(
        queryParamName?: "UiVersion",
        queryParamValue?: string,
        options?: object
    ): Promise<AccountConfigData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.httpAdapter.get<AccountConfigData>(
                `https://flex-api[region].twilio.com/v1/Configuration?${encodeURIComponent(
                    String(queryParamName)
                )}=${encodeURIComponent(String(queryParamValue))}`,
                AuthenticationMethod.Basic,
                options
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }

    






    public async fetchPublicConfiguration(
        queryParamName: "AccountSid" | "RuntimeDomain",
        queryParamValue: string
    ): Promise<PublicConfigResponse> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (queryParamName === null || queryParamName === undefined) {
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter queryParamName was null or undefined."
            );
        }
        if (queryParamValue === null || queryParamValue === undefined) {
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter queryParamValue was null or undefined."
            );
        }

        let response;
        try {
            response = await this.httpAdapter.get<PublicConfigResponse>(
                `https://flex-api[region].twilio.com/v1/Configuration/Public?${encodeURIComponent(
                    String(queryParamName)
                )}=${encodeURIComponent(String(queryParamValue))}`,
                undefined
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<PublicConfigResponse>(response);
        return processedResponse;
    }

    





    public async updateConfiguration(accountConfigData: Partial<AccountConfigData>): Promise<AccountConfigData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountConfigData === null || accountConfigData === undefined) {
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter accountConfigData was null or undefined."
            );
        }

        let response;
        try {
            response = await this.httpAdapter.post<AccountConfigData>(
                `https://flex-api[region].twilio.com/v1/Configuration`,
                AuthenticationMethod.Basic,
                accountConfigData
            );
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `ConfigurationService - ${e.message}`;
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message);
            }

            return Promise.reject(e);
        }
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }
}
