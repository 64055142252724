import { interfaces } from "inversify";

import { ProfileServiceImpl } from "./api/profile.service";
import { ProfileService } from "./api/profile.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<ProfileService>("ProfileService").to(ProfileServiceImpl).inSingletonScope();
    }
}
